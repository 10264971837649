import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Sezione from "../components/sezione"
import SEO from "../components/seo"
import BanneronePage from "../components/banneronePage"
import TitoloPagina from "../components/titoloPagina"
import CardPagina from "../components/cardPagina"
import { Container, Row, Col } from "react-bootstrap"

const margin20 = {
  margin: "5px 5px",
}

const LavoraConNoi = () => {
  return (
    <Layout isPagina={true}>
      <SEO
        title="Soluzioni integrate di Ingegneria Energetica Lazio: impianti fotovoltaici, solari termici, termodinamici, consulenze energetiche, termografia a infrarossi"
        description="Lavora con NSE srl! Visualizza le nuove opportunità nel campo delle energie rinnovabili in tutto il Lazio. "
      />
      <BanneronePage title="Lavora con noi" />
      <Container className="mt-4 mb-4">
        <TitoloPagina title="Lavora con noi" />
        <Row>
          <Col md={8}>
            <CardPagina variante="bordoLeft">
              <p>
                <strong>NonsoloEnergia srl</strong> <br />
                Realizziamo sistemi ad energie rinnovabili ed eseguiamo
                interventi di efficientamento energetico ad alto contenuto
                tecnologico che soddisfino le esigenze dei nostri clienti e
                contribuiscano in maniera attiva a creare un futuro migliore.
                <br />
                NonsoloEnergia srl ha come target finale sia l’azienda che
                vuole approvvigionarsi dalla fonte rinnovabile abbattendo
                drasticamente il fabbisogno energetico aziendale, sia il
                privato che vuole arricchire la propria abitazione di un
                impianto "verde" lasciando il proprio contributo indelebile
                per l’ambiente e nello stesso tempo rendendosi indipendente
                dai costi dell'energia.
                <br /> Se vuoi un futuro pieno di energia per la tua carriera,
                entra nel mondo di NonsoloEnergia nel business dell'energia e
                dei servizi alle imprese.
              </p>
              <p>
                Al momento cerchiamo
                <u>
                  <em>
                    <b> Agenti/Energy Sales </b>
                  </em>
                </u>
                con esperienza nel settore energetico aziendale da inserire
                nel nostro team per potenziare la nostra competitività su
                tutto il territorio, con provvigione sulle vendite, premi
                trimestrali in base ai risultati conseguiti, rapporto a
                partita IVA.
              </p>
              <p>
                <u>
                  E' fondamentale, pena la non considerazione della
                  candidatura inviataci, esprimere nel proprio Curriculum
                  vitae la chiara autorizzazione al trattamento dei dati
                  personali conferiti (D.Lgs.196/2003 e s.m.i.).
                </u>
              </p>
            </CardPagina>
          </Col>
          <Col className="mx-auto mt-3" md={4}>
            <div className="text-center">
              <img
                className="mt-3"
                src="../lavora/lavora-con-noi.jpg"
                alt="Lavora con noi Non Solo Energia"
              />
              <img
                className="mt-3"
                src="../lavora/lavora-con-noi1.jpg"
                alt="Lavora con noi Non Solo Energia"
              />
            </div>

          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default LavoraConNoi
